<template src="./quickAccess.html"></template>

<script>
import mixins from "../../../mixins/mixins.js";
export default {
  mixins: [mixins],
  data() {
    return {
      windowWidth: 0,
      patient: {},
      dates: [],
      scheduleId: 0,
      hashcode: "",
      clinic: {},
      price: 0,
      doctor: {},
      scheduleOne: false,
      scheduleTwo: false,
      dateHour0: "",
      dateHour1: "",
      dateHour2: "",
      match: {},
      doctorPhoto: null,
      telemedicine: false,
    }
  },
  created: function () {
    const vm = this;
    window.addEventListener("resize", vm.handleResize);
    vm.handleResize();
  },
  destroyed: function () {
    const vm = this;
    window.removeEventListener("resize", vm.handleResize);
  },
  mounted: function () {
    var vm = this;
    vm.requestInformations();
  },
  methods: {
    requestInformations() {
      var vm = this;
      vm.$vs.loading();
      vm.scheduleId = this.$route.params.scheduleid;
      vm.hashcode = this.$route.params.token;
      vm.axios.get(
        vm.$store.state.filooServer +
            "schedules/"+ vm.scheduleId + "/quick-access", {
          params: {
            token: vm.hashcode,
          },
        },
      )
        .then( response => {
          vm.validaMatch(response.data.match);
          vm.doctor = response.data.match.doctor
          vm.patient = response.data.patient;
          vm.dates = response.data.dates;
          vm.clinic = response.data.match.clinic;
          vm.price = response.data.price;
          vm.dateHour0 = response.data.match.dateHour;
          vm.dateHour1 = response.data.match.dateHour1;
          vm.dateHour2 = response.data.match.dateHour2;
          vm.match = response.data.match;
          vm.telemedicine = response.data.match.kindAttendance0 === "tele";
          vm.doctorPhoto = 
            response.data.match.doctor.photo ? `data:image/png;base64,${ response.data.match.doctor.photo}` : null;
          if(response.data.match.dateHour1 !== null) {
            vm.scheduleOne = true;
          }
          if(response.data.match.dateHour2 !== null) {
            vm.scheduleTwo = true;
          }
          vm.validaMatch(vm.match);
        })
        .catch( error => {
          console.log({error: error});
          vm.validaMatch(null);
        })
        .finally(() => {
          vm.$vs.loading.close();
        });
    },
    validaMatch(match){
      var vm = this;
      if(match === undefined || match === null) {
        vm.showNotifyWarning(
          `Esta consulta já foi agendada ou está indisponível. 
          Por favor, faça login e verifique o menu 'Consultas' -> 'Agendadas'`,
          6000
        );
        vm.$router.push("/login");
      }
    },
    accept(idPendency, schedule) {
      var vm = this;
      vm.axios
        .put(
          vm.$store.state.filooServer + "schedules/" + idPendency
           + "/quick-access/accept-patient/" + schedule + "/?token=" + this.$route.params.token
        )
        .then(function(response) {
          if(response.status === 200) {
            vm.showNotifySuccess(
              "Sucesso",
              "Sua consulta foi agendada com sucesso!",
              4000
            );
            vm.$router.push("/login");
          }
        })
        .catch(function(error) {
          console.log({error: error});
          if (error.status === 418) 
            vm.showNotifyWarning("O horário solicitado é anterior ao horário atual.", 6000);
          else if (error.status === 412)
            vm.showNotifyWarning("O seu saldo é insuficiente. Por favor, realize uma recarga!", 6000);
          else
            vm.showNotifyWarning("Não foi possível agendar a consulta agora. Por favor, tente novamente mais tarde!", 6000);
          vm.$router.push("/login");
        });
    },
    handleResize() {
      const vm = this;
      vm.windowWidth = window.innerWidth;
    },
  },
}
</script>

<style lang="scss" src="./quickAccess.scss">
</style>